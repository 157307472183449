import axios from 'axios';
import useSWR from 'swr';
import { CtfContentTypes } from './constants';
import { getContent, isWHEnv } from './helper';

const SPACE_ID = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const CONTENTFUL_ACCESS_TOKEN = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
const CONTENTFUL_MANAGEMENT_TOKEN = process.env.CONTENTFUL_MANAGEMENT_TOKEN;
const CONTENTFUL_PREVIEW_TOKEN =
  process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_ACCESS_TOKEN;
let ENVIRONMENT =
  process.env.APP_ENV === 'production'
    ? 'master'
    : isWHEnv() &&
      (process.env.APP_ENV === 'local' || process.env.APP_ENV === 'development')
    ? 'westholme-brand-refresh'
    : 'staging';
let switchedEnvironment = false;
let totalRequests = 0;
let requests = [];
let requestCache = {
  pagesByTags: {},
  tagsIdBySlug: {},
  tagGroups: undefined,
  tagsByTagGroup: {},
  tagsById: {},
};

axios.defaults.validateStatus = false;
axios.defaults.baseURL = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`;
axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${CONTENTFUL_ACCESS_TOKEN}`;

axios.interceptors.response.use((r) => {
  totalRequests++;
  requests.push(r.config.url);
  return r;
});

export function getTotalRequests() {
  return totalRequests;
}

export function getRequests() {
  return requests;
}

export function resetCache() {
  requestCache = {
    pagesByTags: {},
    tagsIdBySlug: {},
    tagGroups: undefined,
    tagsByTagGroup: {},
    tagsById: {},
  };
}

export async function getAllEnvironments() {
  return await axios.get(
    `https://api.contentful.com/spaces/${SPACE_ID}/environments`,
    {
      headers: {
        Authorization: `Bearer ${CONTENTFUL_MANAGEMENT_TOKEN}`,
      },
    }
  );
}

export function isCustomEnvironment() {
  return switchedEnvironment;
}

export function switchToPreview() {
  axios.defaults.baseURL = `https://preview.contentful.com/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`;
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${CONTENTFUL_PREVIEW_TOKEN}`;
}

export function switchToMain() {
  axios.defaults.baseURL = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/${ENVIRONMENT}`;
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${CONTENTFUL_ACCESS_TOKEN}`;
}

export function switchEnvironment(env) {
  axios.defaults.baseURL = `https://cdn.contentful.com/spaces/${SPACE_ID}/environments/${env}`;
  ENVIRONMENT = env;
  switchedEnvironment = true;
}

export function getCurrentEnvironment() {
  return ENVIRONMENT;
}

export async function getAllPages() {
  return await axios.get(
    `/entries?content_type=${CtfContentTypes.PAGE}&include=1`
  );
}

export async function getPage(params, include = 10, locale = undefined) {
  return await axios.get(
    `/entries?content_type=${CtfContentTypes.PAGE}&fields.slug=${
      params.slug
    }&include=${include}${locale ? '&locale=' + locale : ''}`
  );
}

export async function getPageByTags(
  tags,
  pageNumber,
  limit,
  offset = undefined,
  currentPageId = undefined,
  inclusionOperator = 'all',
  locale = undefined,
  order = '-fields.publishDate',
  preview
) {
  offset =
    offset && offset > 0
      ? offset
      : pageNumber === 1
      ? 0
      : (pageNumber - 1) * limit;
  tags = tags.join(',');

  const url = `/entries?content_type=${CtfContentTypes.PAGE}${
    locale ? '&locale=' + locale : ''
  }&fields.tags.sys.id[${
    inclusionOperator ?? 'all'
  }]=${tags}&skip=${offset}&limit=${limit}${
    currentPageId ? '&sys.id[ne]=' + currentPageId : ''
  }&order=${order}`;
  const request = axios.get(url);
  const cacheKey = tags + pageNumber + limit + offset + currentPageId + locale;
  if (!preview && !requestCache.pagesByTags[cacheKey]) {
    requestCache.pagesByTags[cacheKey] = request;
  }

  return await (preview ? request : requestCache.pagesByTags[cacheKey]);
}

export async function getTagIdsBySlug(slugs, preview) {
  const cacheKey = slugs;
  const request = axios.get(
    `/entries?content_type=${CtfContentTypes.TAG}&fields.slug[in]=${slugs}`
  );
  if (!preview && !requestCache.tagsIdBySlug[cacheKey]) {
    requestCache.tagsIdBySlug[cacheKey] = request;
  }

  return await (preview ? request : requestCache.tagsIdBySlug[cacheKey]);
}

export async function getAllTagGroups() {
  if (!requestCache.tagGroups) {
    requestCache.tagGroups = axios.get(
      `/entries?content_type=${CtfContentTypes.TAG_GROUP}`
    );
  }
  return await requestCache.tagGroups;
}

/**
 * Recursively searches parent entries until pages are found
 * @param entityId
 * @return {Promise<*[]>}
 */
export async function getPagesWithEntry(entityId) {
  const res = await axios.get(`/entries?links_to_entry=${entityId}`);
  let pages = [];
  const searchedEntities = [];

  await Promise.all(
    res.data.items.map(async (e) => {
      if (e.sys.contentType.sys.id === 'page') {
        pages.push(e);
        return;
      }

      if (searchedEntities.includes(e.sys.id)) {
        return;
      }
      searchedEntities.push(e.sys.id);

      (await getPagesWithEntry(e.sys.id)).forEach((p) => pages.push(p));
    })
  );

  return pages;
}

/**
 * Returns the dynamic components (carousel or content grid) which are potentially affected by this slug
 * @param slug
 * @return {Promise<*[]>}
 */
export async function getRelatedEntities(slug) {
  const res = await getPage({ slug });
  const page = res.data.items[0];
  const pageTags = page.fields.tags.map(
    (t) => getContent(t.sys.id, res.data.includes.Entry).sys.id
  );
  let relatedEntities = [];

  await Promise.all(
    pageTags.map(async (entityId) => {
      const res = await axios.get(`/entries?links_to_entry=${entityId}`);

      res.data.items
        .filter((i) =>
          ['carousel', 'contentGrid', 'navigationLink'].includes(
            i.sys.contentType.sys.id
          )
        )
        .forEach((i) => relatedEntities.push(i));
    })
  );

  return relatedEntities;
}

export async function getDynamicPages(
  _key,
  queryTagIds,
  paginationNo,
  paginationLimit,
  assets,
  entries,
  locale = undefined,
  offset = undefined,
  currentPageId = undefined,
  inclusionOperator = 'all',
  order,
  preview
) {
  const resp = await getPageByTags(
    queryTagIds,
    paginationNo,
    paginationLimit,
    offset,
    currentPageId,
    inclusionOperator,
    locale,
    order,
    preview
  );
  let filteredNewAssets = [],
    filteredNewEntries = [];

  // Append new assets if not already exist
  if (resp?.data?.includes?.Asset) {
    const existingAssetIds = assets.map((j) => j.sys.id);
    filteredNewAssets = resp.data.includes.Asset.filter(
      (j) => !existingAssetIds.includes(j.sys.id)
    );
  }

  // Append new entries if not already exist
  if (resp?.data?.includes?.Entry) {
    const existingEntryIds = entries.map((j) => j.sys.id);
    filteredNewEntries = resp.data.includes.Entry.filter(
      (j) => !existingEntryIds.includes(j.sys.id)
    );
  }

  return {
    resp,
    filteredNewAssets,
    filteredNewEntries,
  };
}

export function useDynamicPages(
  queryTagIds,
  paginationNo,
  paginationLimit,
  assets,
  entries,
  locale = undefined,
  offset = undefined,
  inclusionOperator = undefined,
  order
) {
  const loadData = queryTagIds && paginationNo && paginationLimit;
  const { data, error } = useSWR(
    () =>
      //only fetch when all params data is available
      loadData
        ? [
            'dynamic-pages',
            queryTagIds,
            paginationNo,
            paginationLimit,
            assets,
            entries,
            locale,
            offset,
            undefined,
            inclusionOperator,
            order,
          ]
        : null,
    getDynamicPages
  );

  return {
    data: data,
    isLoading: loadData && !error && !data,
    isError: error,
  };
}

export async function getTagsByIds(ids) {
  const key = ids.join();
  if (!requestCache.tagsById[key]) {
    requestCache.tagsById[key] = axios.get(
      `/entries?content_type=${CtfContentTypes.TAG}&sys.id[in]=${ids}`
    );
  }

  return await requestCache.tagsById[key];
}

export async function getTagsByTagGroupId(id, preview) {
  const request = axios.get(
    `/entries?content_type=${CtfContentTypes.TAG}&fields.tagGroup.sys.id=${id}&order=fields.name&include=10`
  );
  if (!preview && !requestCache.tagsByTagGroup[id]) {
    requestCache.tagsByTagGroup[id] = request;
  }

  return await (preview ? request : requestCache.tagsByTagGroup[id]);
}

export async function getContentById(id) {
  return await axios.get(`/entries?sys.id=${id}&include=10`);
}

export async function findPagesForSearch(keyword, tags) {
  let tagsQuery = '';
  if (tags && tags.length > 0) {
    tagsQuery = `&fields.tags.sys.id[in]=${tags.join(',')}`;
  }

  const resp = await axios.get(
    `/entries?content_type=${
      CtfContentTypes.PAGE
    }${tagsQuery}&sys.revision[gte]=1&sys.createdAt[exists]=true&query=${encodeURIComponent(
      keyword
    )}`
  );

  return resp.data.items ? resp.data.items.map((p) => p.sys.id) : null;
}

export async function searchPagesByKeyword(
  pageIds,
  paginationNo,
  paginationLimit,
  assets,
  entries,
  locale = undefined
) {
  let offset = 0;
  if (paginationNo > 1) {
    offset = (paginationNo - 1) * paginationLimit;
  }

  const resp = await axios.get(
    `/entries?content_type=${CtfContentTypes.PAGE}&sys.id[in]=${pageIds?.join(
      ','
    )}&sys.revision[gte]=1&sys.createdAt[exists]=true&skip=${offset}&limit=${paginationLimit}&include=10${
      locale ? '&locale=' + locale : ''
    }`
  );

  let filteredNewAssets = [],
    filteredNewEntries = [];

  // Append new assets if not already exist
  if (resp?.data?.includes?.Asset) {
    const existingAssetIds = assets.map((j) => j.sys.id);
    filteredNewAssets = resp.data.includes.Asset.filter(
      (j) => !existingAssetIds.includes(j.sys.id)
    );
  }

  // Append new entries if not already exist
  if (resp?.data?.includes?.Entry) {
    const existingEntryIds = entries.map((j) => j.sys.id);
    filteredNewEntries = resp.data.includes.Entry.filter(
      (j) => !existingEntryIds.includes(j.sys.id)
    );
  }

  return {
    resp,
    filteredNewAssets,
    filteredNewEntries,
  };
}

export async function getRobot() {
  const resp = await axios.get(
    `/entries?content_type=${CtfContentTypes.ROBOT}`
  );
  return resp?.data?.items?.[0]?.fields ?? {};
}
