/**
 * Function to get WEB_ENV or STORYBOOK_WEB_ENV environment variable value
 * if WEB_ENV and STORYBOOK_WEB_ENV does not exists, it will return 'wh' (short for westholme) as default value.
 */
export function getWebEnv() {
  if (process.env.WEB_ENV) {
    return process.env.WEB_ENV;
  } else if (process.env.STORYBOOK_WEB_ENV) {
    return process.env.STORYBOOK_WEB_ENV;
  }

  return 'wh';
}

export const isDDEnv = () =>
  process.env.WEB_ENV === 'dd' || process.env.NEXT_PUBLIC_WEB_ENV === 'dd';
export const isWHEnv = () =>
  process.env.WEB_ENV === 'wh' || process.env.NEXT_PUBLIC_WEB_ENV === 'wh';

/**
 * Function to get asset object from Asset collection of contentful.
 * @param {string} id
 * @param {object[]} assets
 * @returns
 */
export function getAsset(id, assets) {
  const asset = assets.find((item) => item.sys.id === id) || null;
  return asset;
}
/**
 * Function to get content object from Entry collection of contentful.
 * @param {string} id
 * @param {object[]} entries
 * @returns
 */
export function getContent(id, entries) {
  if (!entries?.length) return null;

  const entry = entries.find((item) => item.sys.id === id) || null;
  return entry;
}

/**
 * Function to get content type from given content object of contentful.
 * @param {object} content
 * @returns
 */
export function getContentType(content) {
  return content.sys.contentType.sys.id;
}

/**
 * Function to get fields from a content object of contentful.
 * @param {object} content
 * @returns
 */
export function getFields(content) {
  return content.fields;
}

/**
 * Function to find an Entry collection of contentful's index based on sys.id.
 * @param {string} id
 * @param {object[]} entries
 * @returns
 */
export function findContentIndex(id, entries) {
  return entries.findIndex((item) => item.sys.id === id);
}

/**
 * Function to find Entries in a collection based on their content type
 * @param {string} type
 * @param {object[]} entries
 * @returns
 */
export function findContentsByType(type, entries) {
  return entries.filter((item) => item.sys.contentType.sys.id === type);
}
export function findOneContentByType(type, page, entries) {
  const pageContentBlocks = page.fields.content
    ? page.fields.content.map((c) => c.sys.id)
    : [];
  return entries.find(
    (item) =>
      pageContentBlocks.includes(item.sys.id) &&
      item.sys.contentType.sys.id === type
  );
}

/**
 * Function to get current pagination's page number from URL.
 * @param {string} pathname
 * @param {object[]} entries
 * @returns
 */
export function getPageNumber(pathname) {
  const cleanPathNameWithoutQueryString = pathname.split('?')[0];
  const urlPathnames = cleanPathNameWithoutQueryString.split('/');

  if (urlPathnames[2] && !isNaN(urlPathnames[2])) {
    return urlPathnames[2];
  }

  return undefined;
}

/**
 * Function to merge Assets/Entries without creating duplicates.
 * @param {array} array
 * @param {array} arrayToMerge
 * @returns
 */
export function mergeAssetsOrEntries(array, arrayToMerge = []) {
  if (!arrayToMerge?.length) {
    return array;
  }
  return [
    ...array,
    ...arrayToMerge?.filter((i) => !array.some((j) => j.sys.id === i.sys.id)),
  ];
}

export function removeKeys(obj, keysToRemove) {
  return Object.fromEntries(
      Object.entries(obj).filter(([key]) => !keysToRemove.includes(key))
  );
}

// export function renameKey(obj, oldKey, newKey) {
//   if (obj.hasOwnProperty(oldKey)) {
//     obj[newKey] = obj[oldKey]; // Create new key with the value of old key
//     delete obj[oldKey]; // Delete the old key
//   }
// }

