// Asset position for story component.
export const CtfAssetPositions = {
  LEFT: 'Left',
  RIGHT: 'Right',
};

// Asset type
export const CtfAssetType = {
  IMAGE: 'image',
  VIDEO: 'video',
};

// List of two column content type from contentful
export const CtfTwoColumnContentStyle = {
  LEGAL: 'legal',
  RECIPE_DD: 'recipe-dd',
};

// List of lead generation type from contentful. For analytics purpose
export const CtfLeadGenType = {
  SIGNUP: 'signup',
  OFFER: 'offer',
};

export const CtfWHLeadGenType = {
  PURCHASE: 'Purchase form',
  SIGNUP: 'Sign-up form',
  GENERAL: 'General form',
  PRODUCT: 'Product form',
}

// List of carousel styles from contentful.
export const CtfCarouselStyle = {
  FEATURED: 'Featured',
  FEATURED_FULL: 'Featured (Full Width)',
  HERO: 'Hero',
  NORMAL: 'Normal',
  SLIDER: 'Slider',
  FEATURED_ASYMMETRIC: 'Featured (Asymmetric Content Width)',
  FEATURED_ASYMMETRIC_FULL: 'Featured (Asymmetric Full Width)',
};

// List of carousel styles from contentful.
export const CtfStoryStyle = {
  ASSET: 'Featured Asset',
  NORMAL: 'Normal',
  RECIPE_STEPS: 'Recipe Steps',
};

// List of content types from contentful.
export const CtfContentTypes = {
  CARD: 'card',
  INGREDIENT_SECTION: 'ingredientSection',
  PAGE: 'page',
  TAG: 'tag',
  TAG_GROUP: 'tagGroup',
  NAVIGATION: 'navigation,',
  ROBOT: 'robot',
};

// CTA style enum from contentful
export const CtfCtaStyle = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  // only on DD
  DD_PRIMARY_FILLED: 'DDPrimaryFilled',
  DD_PRIMARY_LINE: 'DDPrimaryLine',
  DD_SECONDARY: 'DDSecondary',
  DD_TERTIARY: 'DDTertiary',
  DD_DEFAULT: 'DDDefault',
};

// Static slug for pages from contentful.
export const CtfPageSlug = {
  HOME: 'home',
};

// List of tags from contentful.
export const CtfTags = {
  STANDALONE: 'standalone',
};

// Pagination defaults
export const PaginationDefaults = {
  LIMIT: 9,
};

// BlockText style from contentful
export const CtfBlockTextStyle = {
  TEXT: 'text',
  BLOCKQUOTE: 'blockquote',
  BLOCKQUOTE_BODY_LARGE: 'blockquote-body-large',
};

// RichText nodeType
export const CtfRichTextTag = {
  HR: 'hr',
  HYPERLINK: 'hyperlink',
  ENTRY_HYPERLINK: 'entry-hyperlink',
  PARAGRAPH: 'paragraph',
  OL: 'ordered-list',
  TABLE: 'table',
  UL: 'unordered-list',
  TABLE_HEADER_CELL: 'table-header-cel',
  BLOCKQUOTE: 'blockquote',
  HEADING_1: 'heading-1',
  HEADING_2: 'heading-2',
  HEADING_3: 'heading-3',
  HEADING_4: 'heading-4',
  HEADING_5: 'heading-5',
  HEADING_6: 'heading-6',
};

// BlockText position from contentful
export const CtfBlockTextPositions = {
  LEFT: 'Left',
  RIGHT: 'Right',
};

// SectionHeader type from contentful
export const CtfSectionHeaderType = {
  PAGE_LARGE: 'page-large', // for DD's 404
  PAGE: 'page',
  SECTION: 'section',
  LEVEL_2: 'level-2',
  LEVEL_3: 'level-3',
};

export const CtfSectionHeaderHeadingSize = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
};

// SectionHeader position from contentful
export const CtfSectionHeaderPositions = {
  CENTER: 'Center',
  LEFT: 'Left',
  RIGHT: 'Right',
};

export const CtfWidthOptions = {
  PADDING: 'Full Padding',
  NO_LEFT: 'No Left Padding',
  NO_RIGHT: 'No Right Padding',
  FULL_WIDTH: 'Full Width',
};

export const CtfChefIntroStyle = {
  RECIPE: 'recipe',
  RESTAURANT: 'restaurant',
  CUSTOM: 'custom',
};

// List of navigation styles from contentful.
export const CtfNavigationStyle = {
  DEFAULT: 'Default',
  STICKY: 'Sticky',
};

// Content Query Order for dynamic
export const CtfContentQueryOrder = {
  CREATE_DATE: 'Create Date',
  PUBLISH_DATE: 'Publish Date',
};

export const ContentQueryOrder = {
  PUBLISH_DATE: '-fields.publishDate',
  CREATE_DATE: '-sys.createdAt',
};
